export default class Toggle {
  /**
   * Create new toggle
   * @param {HTMLElement} trigger Toggle trigger (eg. a button)
   * @param {HTMLElement} target Toggle target (the thing to toggle)
   */
  constructor (trigger, target, options = {}) {
    this.trigger = trigger
    this.target = target
    this.options = options
    this.isActive = false

    this.trigger.setAttribute('aria-controls', this.target.id)
    this.trigger.setAttribute('aria-expanded', 'false')
    this.target.setAttribute('aria-hidden', 'true')

    this.trigger.addEventListener('click', () => {
      this.toggle()
    })

    this.target.addEventListener('transitionend', event => {
      if (event.target === this.target && this.isActive) {
        if (this.options.focus) {
          this.options.focus.focus()
        }
      }
    })
  }

  /**
   * Add toggle class
   */
  activate () {
    this.trigger.classList.add('is-active')
    this.target.classList.add('is-open')

    this.trigger.setAttribute('aria-expanded', 'true')
    this.target.setAttribute('aria-hidden', 'false')

    this.options.focus.focus()

    this.isActive = true
  }

  /**
   * Remove toggle class
   */
  deactivate () {
    this.trigger.classList.remove('is-active')
    this.target.classList.remove('is-open')

    this.trigger.setAttribute('aria-expanded', 'false')
    this.target.setAttribute('aria-hidden', 'true')

    this.isActive = false
  }

  toggle () {
    if (this.isActive) {
      this.deactivate()
    } else {
      this.activate()
    }
  }
}
