import Glide, { Autoplay, Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

const ANIMATION_DURATION = 1 * 1000
const AUTOPLAY_DURATION = 8 * 1000

const slider = document.querySelector('.js-slider-hero')

window.addEventListener('DOMContentLoaded', () => {
  if (slider) {
    const options = {
      type: 'carousel',
      animationDuration: ANIMATION_DURATION,
      autoplay: AUTOPLAY_DURATION,
      hoverpause: true,
      gap: 0,
      dragThreshold: false,
      classes: {
        activeNav: 'is-active'
      }
    }

    new Glide(slider, options).mount({ Autoplay, Controls, Swipe })
  }
})
