import Toggle from '@/functions/toggle'

const button = document.querySelector('.js-header-search-toggle')
const flyout = document.querySelector('.js-header-search-flyout')
const input = document.querySelector('.js-header-search-input')

if (button && flyout && input) {
  /* eslint-disable-next-line no-new */
  new Toggle(button, flyout, { focus: input })
}
