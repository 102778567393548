import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'

const sliders = document.querySelectorAll('.js-slider')

window.addEventListener('DOMContentLoaded', () => {
  for (let i = 0; i < sliders.length; i++) {
    const options = {
      type: 'carousel',
      animationDuration: 800,
      gap: 64,
      dragThreshold: false,
      classes: {
        activeNav: 'is-active'
      }
    }

    new Glide(sliders[i], options).mount({ Controls, Swipe })
  }
})
