const HAS_INPUT_CLASS = 'has-input'

export default class FormInput {
  /**
   * Create new form input
   * @param {HTMLElement} input The input with label and field
   * @param {HTMLFormElement} field The element that receives focus
   */
  constructor (input, field) {
    this.input = input
    this.field = field

    if (this.disallowSmallLabel) {
      this.setSmallLabel()
    }

    this.field.addEventListener('focus', () => this.setSmallLabel())
    this.field.addEventListener('blur', () => this.setLargeLabel())
  }

  /**
   * Small label is not allowed.
   * @type {boolean}
   */
  get disallowSmallLabel () {
    return this.field.value ||
      this.field.type === 'date' ||
      this.field.type === 'time'
  }

  /**
   * Set the label to small size.
   */
  setSmallLabel () {
    this.input.classList.add(HAS_INPUT_CLASS)
  }

  /**
   * Set the label to large size (if allowed)
   */
  setLargeLabel () {
    if (this.disallowSmallLabel) {
      return
    }

    this.input.classList.remove(HAS_INPUT_CLASS)
  }
}
