import Toggle from '@/functions/toggle'

const button = document.querySelector('.js-navigation-main-button')
const list = document.querySelector('.js-navigation-main-list')

if (list && button) {
  /* eslint-disable-next-line no-new */
  new Toggle(button, list, { focus: list })
}

const loginButton = document.querySelector('.js-navigation-main-login-button')
const loginList = document.querySelector('.js-navigation-main-login-list')

if (loginButton && loginList) {
  /* eslint-disable-next-line no-new */
  new Toggle(loginButton, loginList, { focus: loginList })
}
