const topicSelects = document.querySelectorAll('.js-topic-select')
const breakpoint = window.matchMedia('(min-width: 60rem)')

function setState () {
  for (let i = 0; i < topicSelects.length; i++) {
    topicSelects[i].open = breakpoint.matches
  }
}

setState()
breakpoint.addListener(setState)
