import FormInput from '@/functions/form-input'

const inputs = document.querySelectorAll('.js-form-input')

for (let i = 0; i < inputs.length; i++) {
  const field = inputs[i].querySelector('.js-form-input-field')

  if (field) {
    /* eslint-disable-next-line no-new */
    new FormInput(inputs[i], field)
  }
}
